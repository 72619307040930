import React from "react";
import { useState, useEffect } from "react";
import { Hidden, TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import { useContext } from "react";
import { AppContext } from '../../../context/AppContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "../style.css";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import { useRef } from "react";




function StepFive() {



  const { account, setAccount } = useContext(AppContext);
  const [occValue, setOccVal] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorss, setErrorr] = useState(null);
  const [incomeproof, SetIncomeProof] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [imageError, setImageError] = useState(null);
  const [ImageCheck, SetImageCheck] = useState(null);


  const [inputFields, setInputFields] = useState({
    employer_name: '',
    job_title: '',
    department: '',
    business_address: '',
    occupation: '',
    annual_income: '',
    other_occupation: '',
    income_proof: '',
  });

  const { userData, setUserData, api, setApi, uin, setUin } = useContext(AppContext);

  useEffect(() => {
    // Access and use the user data here
    const { Employer, Jobtitle, Department, BusinessAddress, App_Occupation, Kyc_MonthlyNetIncomeID, App_Sub_Occupation_Image, other_occupation } = userData;
    setInputFields(
      {
        ...inputFields,
        occupation: App_Occupation,
        employer_name: Employer,
        job_title: Jobtitle,
        department: Department,
        annual_income: Kyc_MonthlyNetIncomeID,
        business_address: BusinessAddress,
        income_proof: App_Sub_Occupation_Image,
        other_occupation: other_occupation

      })
    if (inputFields.income_proof != null || inputFields.income_proof != undefined) {
      SetImageCheck(inputFields.income_proof)
    }
  }, [userData]);


  const schema = yup.object().shape({

    occupation: yup.string()
      .required('Occupation is Required')
      .nullable(),
    other_occupation: inputFields.occupation == 'P999' ?
      yup.string()
        .required('Other Occupation name is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
        .nullable()
      : yup.string(),
    annual_income: yup.string()
      .required('Annual income is Required')
      .nullable(),
    // {(inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation === 'P014' || inputFields.occupation === 'P019') && (

    employer_name: inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation == 'P014' || inputFields.occupation == 'P019' ?
      yup.string()
        .required('Employer name is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      : yup.string(),
    job_title: inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation == 'P014' || inputFields.occupation == 'P019' ?
      yup.string()
        .required('Job title  is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      : yup.string(),
    department: inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation == 'P014' || inputFields.occupation == 'P019' ?
      yup.string()
        .required('Department name is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      : yup.string(),
    business_address: inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation == 'P014' || inputFields.occupation == 'P019' ?
      yup.string()
        .required('Business address is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      : yup.string(),
  });


  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      province: inputFields.province,

      // Set initial values for other fields
    },
  });
  useEffect(() => {
    // Reset the form when the 'inputFields' change to update the initial values
    reset({
      province: inputFields.province,
      // Update initial values for other fields
    });
  }, [inputFields, reset]);

  const handleImageChange = (event) => {

    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setImageError("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file);
        setInputFields({ ...inputFields, income_proof: file });
        setPreviewImage(reader.result);
        setErrorr(incomeproof);
        SetImageCheck(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      income_proof: null,
    }));
    SetImageCheck(null);
  };

  const errorRef = useRef(null);


  const onSubmit = (event) => {

    if (api == 5 || api >= 5) {

      if (!inputFields.income_proof && (account === 'CLS' || account === 'PRE')) {
        setImageError('Please upload proof of Income ')
        errorRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      setIsLoading(true);
      axios.post(`https://aofapi.bmatrade.com/api/step-five/${uin}`, inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          // Add any other headers you need
        },
      })
        .then((response) => {
          console.log(response);
          setIsLoading(false);

          console.log(response.data.success);

          if (response.data.success === 'true') {
            // Handle the response data
            if (step < 6) {
              const nextStep = step + 1;
              console.log(nextStep);
              setPage(nextStep)
            }
            setApi(6);
          }
          else {
            toast.error(`some fields are required`);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          // Handle any errors that occurred during the request
        });
    }
    else {
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };
  const handleChangeOcc = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    const occVal = e.target.value;
    setOccVal(occVal);

  }
  const [imageErrorMessage, setImageErrorMessage] = useState('');
  const [addProof, setBackSide] = useState([]);
  const { step, setPage } = useContext(AppContext);
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const imgii = inputFields.income_proof;
  const imageUrl = `${baseUrl}${imgii}`;

  return (
    <form id="contactform" onSubmit={handleSubmit(onSubmit)}>
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        <div className="col-md-6">
          <div class="col-md-12 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Occupation</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name='occupation'
                {...register('occupation')}
                label="Occupation"
                error
                onChange={handleChange}
                value={inputFields.occupation}
              >
                <MenuItem value=''>Select occupation</MenuItem>
                <MenuItem value='P001'>Agriculturist</MenuItem>
                <MenuItem value='P002'>Business</MenuItem>
                <MenuItem value='P003'>Business Executive</MenuItem>
                <MenuItem value='P005'>House Hold</MenuItem>
                <MenuItem value='P006'>House Wife</MenuItem>
                <MenuItem value='P007'>Industrialist</MenuItem>
                <MenuItem value='P012'>Professional</MenuItem>
                <MenuItem value='P013'>Retired Person</MenuItem>
                <MenuItem value='P014'>Service</MenuItem>
                <MenuItem value='P015'>Student</MenuItem>
                <MenuItem value='P019'>Govt./Public Sector</MenuItem>
                <MenuItem value='P999'>Others</MenuItem>
              </Select>
            </FormControl>
            <p className="text-danger fs-6"> {errors.occupation && <p>{errors.occupation.message}</p>} </p>
          </div>
          {inputFields.occupation === 'P999' && (
            <>
              <div className="row col-md-12 p-0 mx-auto mb-4">
                <TextField
                  error
                  id="outlined-error"
                  label="Type Your Occupation"
                  name="other_occupation"
                  {...register('other_occupation')}
                  onChange={handleChange}
                  value={inputFields.other_occupation} />

                <p className="text-danger fs-6"> {errors.other_occupation && <p>{errors.other_occupation.message}</p>} </p>

              </div>
            </>
          )}
          <div className="row col-md-12 p-0 mx-auto mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Gross Annual Income</InputLabel>
              {account == 'SAH' ?
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='annual_income'
                  label="Gross Annual Income"
                  error
                  {...register('annual_income')}
                  onChange={handleChange}
                  value={inputFields.annual_income}
                >
                  <MenuItem>select</MenuItem>
                  <MenuItem value='j07'>UP TO 100,000</MenuItem>
                  <MenuItem value='j08'>100,001 - 250,000</MenuItem>
                  <MenuItem value='j09'>250,001 - 500,000</MenuItem>
                  <MenuItem value='j10'>Above 500,000</MenuItem>
                </Select>
                :
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='annual_income'
                  {...register('annual_income')}
                  label="Gross Annual Income"
                  error
                  onChange={handleChange}
                  value={inputFields.annual_income}
                >
                  <MenuItem>select</MenuItem>
                  <MenuItem value='j05'>1,000,001 - 2,500,000</MenuItem>
                  <MenuItem value='J06'>Above 2,500,000</MenuItem>
                  <MenuItem value='j01'>UP TO 100,000</MenuItem>
                  <MenuItem value='j02'>100,001 - 250,000</MenuItem>
                  <MenuItem value='j03'>250,001 - 500,00</MenuItem>
                  <MenuItem value='j04'>500,001 - 1,000,000</MenuItem>
                </Select>
              }
              <p className="text-danger fs-6"> {errors.annual_income && <p>{errors.annual_income.message}</p>} </p>
            </FormControl>
          </div>
          {(inputFields.occupation == 'P002' || inputFields.occupation == 'P003' || inputFields.occupation == 'P007' || inputFields.occupation == 'P012' || inputFields.occupation === 'P014' || inputFields.occupation === 'P019') && (

            <>
              <div className="row col-md-12 p-0 mx-auto mb-4">
                <TextField
                  error
                  id="outlined-error"
                  label="Employer Name "
                  name="employer_name"
                  {...register('employer_name')}
                  onChange={handleChange}
                  value={inputFields.employer_name}

                />
                <p className="text-danger fs-6"> {errors.employer_name && <p>{errors.employer_name.message}</p>} </p>

              </div>
              <div className="row col-md-12 p-0 mx-auto mb-4">
                <TextField
                  error
                  id="outlined-error"
                  label="Job Title"
                  name="job_title"
                  {...register('job_title')}
                  onChange={handleChange}
                  value={inputFields.job_title}
                />
                <p className="text-danger fs-6"> {errors.job_title && <p>{errors.job_title.message}</p>} </p>
              </div>
              <div className="row col-md-12 p-0 mx-auto mb-4">
                <TextField
                  error
                  id="outlined-error"
                  label="Department"
                  name="department"
                  {...register('department')}
                  onChange={handleChange}
                  value={inputFields.department}

                />
                <p className="text-danger fs-6"> {errors.department && <p>{errors.department.message}</p>} </p>

              </div>


              <div className="row col-md-12 p-0 mx-auto mb-4">
                <TextField
                  error
                  id="outlined-error"
                  label="Business Addres"
                  name="business_address"
                  {...register('business_address')}
                  onChange={handleChange}
                  value={inputFields.business_address}
                />
                <p className="text-danger fs-6"> {errors.business_address && <p>{errors.business_address.message}</p>} </p>
              </div>
            </>
          )}

          {(account === 'CLS' || account === 'PRE') && (
            <>
              <div>
                <label style={{ color: '#c42026' }}><strong>Proof of Income </strong></label>
              </div>
              <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
                {!inputFields.income_proof ? (
                  <>
                    <label htmlFor="imageInput">
                      <Button
                        variant="text"
                        component="label"
                        className="nic_uploadBtn"
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div>
                          <AddAPhotoIcon />
                        </div>
                        Proof of income
                        <input
                          hidden
                          className="form-control"
                          id="image"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleImageChange}
                          type="file"
                          name="image"
                        />
                      </Button>
                      {imageError && (
                        <p ref={errorRef} className="text-danger fs-6">{imageError}</p>
                      )}
                    </label>
                    <p className="text-danger fs-6"> {errorss && <p>{errorss}</p>} </p>
                  </>
                ) : (
                  <>
                    <div className="preview">
                      <div className="nicPreview">
                        {ImageCheck != null || ImageCheck != undefined ? (
                          <img src={imageUrl} alt="Preview" />
                        ) : (
                          <img src={previewImage} alt="Preview" />
                        )
                        }
                        <button
                          className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                          onClick={handleImageDelete}
                        >
                          <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/6
              </button>
            ) : (
              <div className="spinner-border  text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <button type="submit" className="btn btn next-color text-white rounded"
            >
              Next <img height={20} src={gonext} /></button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default StepFive;

