import React from "react";
import Alert from '@mui/material/Alert';


function Sahulat() {

    return (



        <div className="row col-md-12">
            <div className="col-md-4">

            </div>
            <div className="col-md-8  justify-content-left py-5">
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}></i>
                    &nbsp; CNIC
                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}> </i>
                    &nbsp; Mobile Number

                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}></i>
                    &nbsp;  IBAN
                </p>
               

                <Alert className="col-md-6 mt-3" severity="error"> 
                <ul>
                  <li>NICOP holders are not Eligible for Sahulat Account</li>
                  <li>If you already have an equity account, you are not eligible for a Sahulat account</li>
                </ul>
                </Alert>
            </div> 
        </div>


    )

}


export default Sahulat;