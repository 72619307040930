import React from "react";


const Info = () =>
{
    return(
     <h2> this is info</h2>
    )
    
    
};

export default Info;