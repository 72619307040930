import React, { useContext } from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import {AppContext} from '../../../context/AppContext';



const MultiStepProgressBar = () => {

  const { step, setPage, api } = useContext(AppContext);
  
console.log({api});
  return (
    <ProgressBar  percent=  {step === 1 ? 0 : step === 2 ? 17 : step === 3 ?  34 : step === 4 ? 50  : step === 5 ? 67 : step === 6 ? 84  : 100}
                   >
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(1)}
          >
            {'✔'}
            
          </div>
         
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(2)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(3)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(4)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(5)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(6)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => setPage(7)}
          >
            {'✔'}
          </div>
        )}
      </Step>
      
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
