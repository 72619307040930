import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import Button from '@mui/material/Button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import "../style.css";
import { useContext } from "react";
import { AppContext } from '../../../context/AppContext';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import { useRef } from "react";


function StepThree() {


  
 
  const [countries, setOptions] = useState([]);
  const [cities, setCities] = useState([]);
  const { account, setAccount } = useContext(AppContext);
  const { uin, setUin } = useContext(AppContext);
  const [selectedReferenceImage, setSelectedReferenceImage] = useState(null);
  const [previewReferenceImage, setPreviewReferenceImage] = useState(null);
  const [referenceImage, setRefernceImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const [imageError, setImageError] = useState(null);

  const [ImageCheck, setImageCheck] = useState(null);


  const [addressError, setAddressInfo] = useState(null); // to show error 
  const [addressRefError, setAddRefError] = useState(null); // to show error 
  // const [iban, SetIban] = useState(null);  // to check if image exist in db

  // const [ibanImage, setIbanImage] = useState(null); // to show error 


  // console.log(previewImage);


  const [inputFields, setInputFields] = useState({

    
    city: '',
    province: '',
    address_info: '',
    mail_address: '',
    address_reference: '',
    reference_image: '',
    account_type : '',



    // password: "",
    // age: null
  });
  const { userData, setUserData , api, setApi} = useContext(AppContext);

  useEffect(() => {
    // Access and use the user data here
    const { MailingCountry, MailingCity, MailingProvince, PermanentMailAddress1, address_info, P_MailAddressReference, App_Cnic , P_MailAddressImage, Account_Package} = userData;
    setInputFields(
      {
        ...inputFields,
        city: MailingCity,
        province: MailingProvince,
        country: MailingCountry,
        mail_address: PermanentMailAddress1,
        address_info: address_info,
        address_reference: P_MailAddressReference,
        App_Cnic,
        reference_image: P_MailAddressImage,
        account_type: Account_Package,
      })

      if(inputFields.reference_image != null || inputFields.reference_image != undefined)
      {
        setImageCheck(inputFields.reference_image)
      }
        const { data } =  axios.get('https://aofapi.bmatrade.com/api/userdetail/' + App_Cnic);
        console.log(data);     
    }, [userData]);

    const schema = yup.object().shape({
      province: yup.string()   
      .required('Province is required')
      .nullable(),
      city: yup.string()
      .required('City is required')
      .nullable(),
      mail_address: inputFields.address_reference == 2 ?
       yup.string().required().max(100, 'Mailing Address is too long')
      .required('Mailing address is required')
      .nullable()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
       : yup.string(),
      //  address_reference: inputFields.address_info == 2 ?
      //  yup.string().
      //  required('please select Address reference')
      //  .nullable()
      //  : yup.string(),
      // mail_address: inputFields.mob_registration == 2 ? yup.string().required() : yup.string(),
  
      
      // Add more validation rules for other fields
    });


     const { register, handleSubmit, formState: { errors } , reset } = useForm({
    resolver: yupResolver(schema),
    
    defaultValues: {
      province: inputFields.province,
    
      // Set initial values for other fields
    },
  });
  useEffect(() => {
    // Reset the form when the 'inputFields' change to update the initial values
    reset({
      province: inputFields.province,
      // Update initial values for other fields
    });
  }, [inputFields, reset]);

  const handleRefrenceImageChange = (event) => {

    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setImageError("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedReferenceImage(file);
        setInputFields({ ...inputFields, reference_image: file });
        setPreviewReferenceImage(reader.result);
        // setErrorr(referenceImage);
        setImageCheck(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleReferenceImageDelete = () => {
    setSelectedReferenceImage(null);
    setPreviewReferenceImage(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      reference_image: null,
    }));
    setImageCheck(null);

    // setErrorr(null);
  };


  const errorRef = useRef(null);
  const errorAddressInfo = useRef(null);
  const errorAddressRef = useRef(null);



  const onSubmit = (data) => {

    if (api == 3 || api >= 3 )    {

      
      if (!inputFields.address_info) {
        setAddressInfo('Address info required ')
        // Perform necessary error handling or display an error message to the user
        errorAddressInfo.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      if (!inputFields.address_reference && inputFields.address_info == '2') {
        setAddRefError('Address reference is requiered ')
        // Perform necessary error handling or display an error message to the user
        errorAddressRef.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }


      if (!inputFields.reference_image == 2 && (account === 'CLS' || account === 'PRE')) {
        setImageError('Please upload proof of Address ')
        // Perform necessary error handling or display an  error message to the user
      
        errorRef.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      setIsLoading(true);
    axios.post(`https://aofapi.bmatrade.com/api/step-three/${uin}`,inputFields, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        // Add any other headers you need
      },
    })
      .then((response) => { 
        console.log(response);
        setIsLoading(false);
        setApi(4);

        // Handle the response data
        if (step < 6) {
          const nextStep = step + 1;
          console.log(nextStep);
          setPage(nextStep)
        }
      })
      .catch((error) => {
        console.error(error);
        console.log(error);
        // Handle any errors that occurred during the request
      });
    }
    else{
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };



  const [submitting, setSubmitting] = useState(false);

  const province = inputFields.province;
  // const validateValues = (inputValues) => {
  //   let errors = {};
  //   if (inputValues.name.length < 2) {
  //     errors.name = "Email is";
  //   }
  //   // if (inputValues.password.length < 5) {
  //   //   errors.password = "Password is too short";
  //   // }
  //   // if (!inputValues.age || inputValues.age < 18) {
  //   //   errors.age = "Minimum age is 18";
  //   // }
  //   return errors;
  // };
  const [loading, setLoading] = useState(true);

  async function getCityByProvince(province) {
    try {
      const response = await axios.get(`https://aofapi.bmatrade.com/api/cities/${province}`);
      const cities = response.data;
      setCities(cities);

      // Do something with the cities data
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }
  const handleProvince = async (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    const province = e.target.value;
    await getCityByProvince(province);
  }

  useEffect(() => {
    if (province !== '') {
      getCityByProvince(province);
    }
  }, [province]);
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const countries = async () => {
      try {
        const response = await axios.get('https://aofapi.bmatrade.com/api/countries');
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    countries();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://aofapi.test',
  };

  const dd = (event) => {
    event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }

    // console.log(inputFields);
    // setErrors(validateValues(inputFields));
    // setSubmitting(true);
    // console.log(errors);
    axios.post(('https://aofapi.bmatrade.com/api/addressDetail/' + uin), inputFields, { headers })
      .then((response) => {
        console.log(response);
        // Handle the response dat

      })
      .catch((error) => {
        // console.log(response);
        // Handle any errors that occurred during the POST request
        console.error(error);
        // if (step < 6) {
        //   const nextStep = step + 1;
        //   console.log(nextStep);
        //   setPage(nextStep)
        // }
      });


  };
  const [showDiv, setShowDiv] = useState(false);
  const [showDiv2, setShowDiv2] = useState(false);




  const handleRadioChange = (event) => {
    const value = event.target.value;
    // console.log(value);

    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
    setShowDiv(value == '2'); // Set showDiv to true if option1 is selected, otherwise false
  };




  const [status, setStatus] = React.useState(1) // 0: no show, 1: show yes, 2: show no.
  const radioHandler = (status) => {
      setStatus(status);
      setInputFields({ ...inputFields, address_info: status });
  };

  // const handleRadioChangeCnic = (event) => {

  //   const value = event.target.value;
  //   console.log(value);
  //   // setInputFields({ ...inputFields, [event.target.name]: event.target.value });
  //   setShowDiv2(value === '2'); // Set showDiv to true if option1 is selected, otherwise false

  // };

  const [status2, setStatus2] = React.useState(1)
  const radioHandlerCnic = (status2) => {
    setStatus(status2);
    setInputFields({ ...inputFields, address_reference: status2 });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [imageErrorMessage, setImageErrorMessage] = useState('');


  const [addProof, setBackSide] = useState([]);

  


  const { step, setPage } = useContext(AppContext);
  const handleinc = () => {
    // event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };

  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const imgii = inputFields.reference_image;
  const imageUrl = `${baseUrl}${imgii}`;  


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        <div className="col-md-6">

          <div class="row">
            <div class="col-md-6 mb-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Province / State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='province'
                  {...register('province', { nullable: true })}
                  label="province state"
                  error
                  value={inputFields.province}
                  onChange={handleProvince}
                >
                  <MenuItem >Select</MenuItem>
                  <MenuItem value='01'>FATA / FANA</MenuItem>
                  <MenuItem value='02'>SINDH</MenuItem>
                  <MenuItem value='03'>PUNJAB</MenuItem>
                  <MenuItem value='04'>KHYBER PAKHTUNKHWA</MenuItem>
                  <MenuItem value='05'>BALOCHISTAN</MenuItem>
                  <MenuItem value='06'>FEDERAL CAPITAL</MenuItem>
                  <MenuItem value='07'>A.J.K</MenuItem>
                </Select>
              </FormControl>
              <p className="text-danger fs-6"> {errors.province && <p>{errors.province.message}</p>} </p>

            </div>
            <div class="col-md-6 mb-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City town Viilage</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='city'
                  {...register('city')}
                  label="City town Viilage"
                  error
                  value={inputFields.city}
                  onChange={handleChange}
                >
                  <MenuItem value=''>select</MenuItem>
                  {cities.map((city) => (
                    <MenuItem key={city.City_ID} value={city.City_ID}> {city.City_Desc} </MenuItem>
                  ))}
                </Select> 
              </FormControl>
              <p className="text-danger fs-6"> {errors.city && <p>{errors.city.message}</p>} </p>

            </div>
          </div>
          <strong className="font-color-theme"> Addres Info</strong>
          <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">
            <div className="col-md-8 mt-1">
              <p className="font-color-grey"> Is your Mailing Address same as your permenant address? </p>
            </div>
            <div className="col-md-4 py-0 mr-5">
              <Radio
                checked={inputFields.address_info == 1} onClick={(e) => radioHandler(1)}
                name="address_info"
                value={inputFields.address_info}
                inputProps={{ 'aria-label': '1' }}
                sx={{ color: '#c42026!important', }}
              />
              Yes
              <Radio
                checked={inputFields.address_info == 2} onClick={(e) => radioHandler(2)}  
                name="address_info"
                value={inputFields.address_info}
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': '2' }}
              />
              No
            </div>
          </div>
          {addressError && (
                            <p ref={errorAddressInfo} className="text-danger fs-6">{addressError}</p>
                          )}
          {inputFields.address_info == 2 && (
            <>            <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">
              <div className="col-md-8 mt-1">
                <p className="font-color-grey"> Is your Mailing Address Listed on your CNIC? </p>
              </div>
              <div className="col-md-4 py-0 mr-5">
                <Radio
                  checked={inputFields.address_reference == 1} onClick={(e) => radioHandlerCnic(1)}
                  //  onChange={handleRadioChangeCnic}
                  {...register('address_reference')}
                  name="address_reference"
                  value={inputFields.address_reference}
                  inputProps={{ 'aria-label': '01' }}
                  sx={{ color: '#c42026!important', }}
                />
                Yes
                <Radio
                  checked={inputFields.address_reference == 2} onClick={(e) => radioHandlerCnic(2)}
                  //  onChange={handleRadioChangeCnic}
                  {...register('address_reference')}
                  name="address_reference"
                  sx={{ color: '#c42026!important' }}
                  inputProps={{ 'aria-label': '02' }}
                  value={inputFields.address_reference}

                />
                No
              </div>
              
            </div>
            {addressRefError && (
                            <p ref={errorAddressRef} className="text-danger fs-6">{addressRefError}</p>
                          )}
            
            </>
          )}


          {inputFields.address_reference == 2 && (
            <>
              <div className="row col-md-12 p-0 mx-auto mb-4">

                <TextField
                  error
                  id="outlined-error"
                  label="Mailing Address "
                  inputProps={{ color: 'yellow!important' }}
                  name="mail_address"
                  {...register('mail_address', { nullable: true })}
                  onChange={handleChange}
                  value={inputFields.mail_address}
                />
                <p className="text-danger fs-6"> {errors.mail_address && <p>{errors.mail_address.message}</p>} </p>

              </div>
              {(account === 'CLS' || account === 'PRE') && (
                <div>
                  <label style={{ color: '#c42026' }}><strong>Proof of Address </strong></label>
                </div>
              )}
              {(account === 'CLS' || account === 'PRE') && (
                <div class="row col-md-12 align-items-center ">
                   <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
      {!inputFields.reference_image  ? (
        <>
          <label htmlFor="imageInput">
          <Button
                    variant="text"
                    component="label"
                    className="nic_uploadBtn"
                    sx={{ textTransform: "capitalize" }}
                  >
                    <div>
                      <AddAPhotoIcon />
                    </div>
                    Proof of Address
                    <input
                      hidden
                      className="form-control"
                      id="image"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleRefrenceImageChange}
                      type="file"
                      name="image"

                    />
                  </Button>
            {/* <button className="upload-button" onClick={handleImageUpload}>
            Upload
          </button> */}
          </label>
          {imageError && (
                            <p ref={errorRef} className="text-danger fs-6">{imageError}</p>
                          )}
          {/* <p className="  text-danger fs-6"> {errorss  && <p>{errorss}</p>} </p> */}
        </>
      ) : (
        <>
        <div className="preview">
        <div className="nicPreview">
          
          {/* <img src={previewImage} alt="Preview" /> */}
          {ImageCheck != null || ImageCheck != undefined ? (
          
                    <img src={imageUrl} alt="Preview" />



          ) : (

            <img src={previewReferenceImage} alt="Preview" />

          )
              }    

          <button
            className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
            onClick={handleReferenceImageDelete}
          >
            <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
          </button>
        
          
        </div>
        </div>
        </>
      )}
    </div>
                </div>
              )}
            </>
          )}

          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
              {!isLoading ? (
  <button className="btn btn text-danger rounded">
    {step}/6
  </button>
) : (
  <div className="spinner-border text-danger" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
)}
            <button type="submit" className="btn btn next-color text-white rounded"
            >
              Next <img height={20} src={gonext} /></button>
          </div>
        </div>
      </div>
    </form>


  )

}

export default StepThree;

