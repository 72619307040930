import React from "react";
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { useState, useEffect } from "react";
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import "../style.css";
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import TermsAndCondition from "./termsAndCondition";
import { useRef } from "react";



function StepSeven() {

  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);



  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorss, setErrorr] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [imagecheck, setImageCheck] = useState(null);

  const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {

    handleClickOpen();

    // setInputFields({ ...inputFields, [event.target.name]: event.target.value }); 
    // setInputFields({ ...inputFields, terms: status });
  };
  const [inputFields, setInputFields] = useState({
    signature_image: '',
    terms: '',
  });

  const { userData, setUserData, api, setApi, uin, setUin, tradercheck, } = useContext(AppContext);

  useEffect(() => {
    // Access and use the user data here
    const { ZakatStatus, App_Signature } = userData;
    setInputFields(
      {
        ...inputFields,
        signature_image: App_Signature,


      })
    if (inputFields.signature_image != null || inputFields.signature_image != undefined) {
      setImageCheck(inputFields.signature_image)
    }
  }, [userData]);
  const schema = yup.object().shape({
  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      province: inputFields.province,
    },
  });
  useEffect(() => {
    reset({
      province: inputFields.province,
    });
  }, [inputFields, reset]);
  const handleImageChange = (event) => {
     const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setImageError("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file);
        setInputFields({ ...inputFields, signature_image: file });
        setPreviewImage(reader.result);
        setErrorr(signature);
        setSignature(null);
        setImageCheck(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const navigate = useNavigate();
  const handleImageDelete = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setErrorr(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      signature_image: null,
    }));
    setImageCheck(null);
  };

  const errorRef = useRef(null);

  const { step, setPage } = useContext(AppContext);
  const onSubmit = (event) => {

    if (api == 7 || api >= 7) {

      if (!inputFields.signature_image) {
        setImageError('Please upload Specimen Signature image')
        errorRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      setIsLoading(true);

      axios.post(`https://aofapi.bmatrade.com/api/step-seven/${uin}`, inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          // Add any other headers you need
        },
      })
        .then((response) => {
          console.log(response);
          setIsLoading(false);

          console.log(response.data.success);

          if (response.data.success === 'true') {
            // Handle the response data

            // if (step < 7) {
            //   const nextStep = step + 1;
            //   console.log(nextStep);
            //   setPage(nextStep)
            // }
            navigate('/submit');
            window.location.reload(true);

            setApi(7);
          }
          else {
            toast.error(`some fields are required`);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          // Handle any errors that occurred during the request
        });
    }
    else {
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const opendoc = () => {
    handleClickOpen();

  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {

    if (event == '1') {
      setInputFields({ ...inputFields, ['terms']: 1 });
    }

    setOpen(false);

  };

  const handleDisagree = (event) => {
    setOpen(false);


  };

  const handleCheckboxChange = (event) => {


    setInputFields({ ...inputFields, [event.target.name]: event.target.value });

    console.log(event.target.checked);
    if (event.target.checked) {
      opendoc();
    }

  };

  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };

  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const imgii = inputFields.signature_image;
  const imageUrl = `${baseUrl}${imgii}`;


  return (
    <form id="contactform" onSubmit={handleSubmit(onSubmit)}>
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        {/* <div className="col-md-3">
      </div> */}
        <div className="col-md-6">

          {/* {imageErrorMessage && (
          <div className="alert success mt-5">{imageErrorMessage}</div>
        )} */}
          <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
            {!inputFields.signature_image ? (
              <>
                <label htmlFor="imageInput">
                  <Button
                    variant="text"
                    component="label"
                    className="nic_uploadBtn"
                    sx={{ textTransform: "capitalize" }}
                  >
                    <div>
                      <AddAPhotoIcon />
                    </div>
                    Specimen Signature
                    <input
                      hidden
                      className="form-control"
                      id="image"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleImageChange}
                      type="file"
                      name="image"
                    />
                  </Button>
                </label>
                {imageError && (
                  <p ref={errorRef} className="text-danger fs-6">{imageError}</p>
                )}
              </>
            ) : (
              <>
                <div className="preview">
                  <div className="nicPreview">

                    {/* <img src={previewImage} alt="Preview" /> */}
                    {imagecheck != null || imagecheck != undefined ? (
                      <img src={imageUrl} alt="Preview" />
                    ) : (
                      <img src={previewImage} alt="Preview" />
                    )
                    }
                    <button
                      className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                      onClick={handleImageDelete}
                    >
                      <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                    </button>
                  </div>
                </div>
              </>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Terms & Conditions"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <ul>
                    <TermsAndCondition />
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleDisagree}>Disagree</Button> */}
                <Button onClick={(event) => handleClose(1)} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/7
              </button>
            ) : (
              <div className="spinner-border  text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {inputFields.terms == '1' ? (
              <button type="submit" className="btn btn next-color text-white rounded"

              >
                Next <img height={20} src={gonext} /></button>
            ) : (
              <button type="button" className="btn btn next-color text-white rounded"
                onClick={(event) => radioHandler(1)}
              >
                Next <img height={20} src={gonext} /></button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
export default StepSeven;

