import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import nicfornt from '../../../assets/nic_front.png'
import ibb from '../../../cnic/1688971159230.png'
import "../style.css";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import { useRef } from "react";



function StepTwo() {


  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const { uin, setUin } = useContext(AppContext);
  const [age, setAge] = React.useState('');
  const [a, setSelectedValue] = React.useState('01');
  const [countries, setOptions] = useState([]);
  const [backSide, setBackSide] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorss, setErrorr] = useState(null);
  const [iban, SetIban] = useState(null);  // to check if image exist in db
  const [ibanImage, setIbanImage] = useState(null); // to show error 
  const [resError, setResError] = useState(null); // to show error 
  const { set, setdat, checkValidateFields, api, setApi, account, setAccount } = useContext(AppContext);

  const [inputFields, setInputFields] = useState({

    resident_status: '',
    nationality: '',
    marital_status: '',
    cnic2: '',
    image: '',
  });


  const [appimg, setImg] = useState({

    imgff: '',
  });
  // const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [imageErrorMessage, setImageErrorMessage] = useState('');
  const { userData, setUserData } = useContext(AppContext);


  useEffect(() => {

    // Access and use the user data here
    const { Residential_Status, App_Nationality, Kyc_MaritalStatus, App_Cnic, Back_Cnic, proof_iban } = userData;
    setInputFields(
      {
        ...inputFields,
        resident_status: Residential_Status,
        nationality: App_Nationality,
        marital_status: Kyc_MaritalStatus,
        image: proof_iban,

      })
    if (inputFields.image != null || inputFields.image != undefined) {
      SetIban(inputFields.image)
    }
  }, [userData]);

  const schema = yup.object().shape({

    marital_status: yup.string().required('Marital status is required'),
    nationality: yup.string().required('Nationality is required'),
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      nationality: inputFields.marital_status,
      marital_status: inputFields.marital_status,
      resident_status: inputFields.resident_status, // Set the initial value for 'cnic' field

      // Set initial values for other fields
    },
  });
  useEffect(() => {
    // Reset the form when the 'inputFields' change to update the initial values
    reset({
      cnic: inputFields.cnic, // Update the initial value for 'cnic' field
      // Update initial values for other fields
    });
  }, [inputFields, reset]);

  const handleImageChange = (event) => {

    const file = event.target.files[0];

    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setIbanImage("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file);
        setInputFields({ ...inputFields, image: file });
        setPreviewImage(reader.result);

        SetIban(null);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleImageDelete = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setErrorr(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      image: null,
    }));
  };



  const handleChange = (e) => {


    setInputFields({ ...inputFields, [e.target.name]: e.target.value });


  };

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://aofapi.test',
  };


  const [status, setStatus] = React.useState(1) // 0: no show, 1: show yes, 2: show no.

  // setStatus('1');
  const radioHandler = (status) => {
    setStatus(status);
    setInputFields({ ...inputFields, resident_status: status });
  };

  

  useEffect(() => {
    const countries = async () => {
      try {
        const response = await axios.get('https://aofapi.bmatrade.com/api/countries');
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    countries();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);


  }, []);

  const { step, setPage } = useContext(AppContext);
  const handleinc = () => {
    // event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
  }
  const errorRef = useRef(null);
  const errorRes = useRef(null)

  // submission 

  const onSubmit = (data) => {

    if (api == 2 || api >= 2) {



      if (!inputFields.image) {
        setIbanImage('please upload proof of IBAN ')
        // Perform necessary error handling or display an error message to the user
        errorRef.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }

      if (!inputFields.resident_status) {
        setResError('please Select Resident Status ')
        // Perform necessary error handling or display an error message to the user
        errorRes.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      setIsLoading(true);


      axios.post(`https://aofapi.bmatrade.com/api/step-two/${uin}`, inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          // Add any other headers you need
        },
      })
        .then((response) => {
          console.log(response);
          setIsLoading(false);

          // Handle the response data
          if (step < 6) {
            const nextStep = step + 1;
            console.log(nextStep);
            setPage(nextStep)
            setApi(3);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          // Handle any errors that occurred during the request
        });
      // .then((response) => {
      //     console.log(response);
      //     // Handle the response data
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     // Handle any errors that occurred during the request
      //   });

      // console.log(inputFields);
      // setErrors(validateValues(inputFields));
      // setSubmitting(true);
      // console.log(errors);
      // axios.post(('http://sales-crm.test/api/DataVerification/'+ uin),inputFields ,{ headers })
      //   .then((response) => {
      //     console.log(response);
      //     // Handle the response dat

      //   })
      //   .catch((error) => {
      //     // console.log(response);
      //     // Handle any errors that occurred during the POST request
      //     console.error(error);
      //     // if (step < 6) {
      //     //   const nextStep = step + 1;
      //     //   console.log(nextStep);
      //     //   setPage(nextStep)
      //     // }
      //   });
    }
    else {
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };
  const imagePath = require(`../../../cnic/1688971159230.png`).default;


  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const imgii = inputFields.image;
  const imageUrl = `${baseUrl}${imgii}`;





  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        {/* <div className="col-md-3">
      </div> */}
        <div className="col-md-6">
          <strong className="font-color-theme" > Proof of IBAN  </strong>
          <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
            {!inputFields.image ? (
              <>
                <label htmlFor="imageInput">
                  <Button
                    variant="text"
                    component="label"
                    className="nic_uploadBtn"
                    sx={{ textTransform: "capitalize" }}
                  >
                    <div>
                      <AddAPhotoIcon />
                    </div>
                    Proof of IBAN
                    <input
                      hidden
                      className="form-control"
                      id="image"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleImageChange}
                      type="file"
                      name="image"

                    />
                  </Button>
                  {/* <button className="upload-button" onClick={handleImageUpload}>
            Upload
          </button> */}
                </label>
                <p className="text-danger fs-6"> {errorss && <p>{errorss}</p>} </p>
                {ibanImage && (
                  <p ref={errorRef} className="text-danger fs-6">{ibanImage}</p>
                )}

              </>
            ) : (
              <>
                <div className="preview">
                  <div className="nicPreview">

                    {/* <img src={previewImage} alt="Preview" /> */}
                    {iban != null || iban != undefined ? (

                      <img src={imageUrl} alt="Preview" />



                    ) : (
                      <img src={previewImage} alt="Preview" />

                    )
                    }

                    <button
                      className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                      onClick={handleImageDelete}
                    >
                      <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                    </button>


                  </div>
                </div>
              </>
            )}
          </div>



          <strong className="font-color-theme"> Residental Status  </strong>
          <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">
            <div className="col-md-6 mt-1">
              <p className="font-color-grey"> Are you a resident of Pakistan?  </p>
            </div>
            <div className="col-md-6 py-0 mr-5">

              <Radio
                checked={inputFields.resident_status == '1'} onClick={(e) => radioHandler(1)}
                // onChange={handleChange}
                name="resident_status"
                sx={{ color: '#c42026!important', }}
                value={inputFields.resident_status}


              // {...register('resident_status', { required: 'Nationality is required' })}             
              // onChange={handleChang
              />
              Resident

              <Radio
                checked={inputFields.resident_status == '2'} onClick={(e) => radioHandler(2)}
                // onChange={handleChange}
                name="resident_status"
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': '2' }}
                value={inputFields.resident_status}

              // {...register('resident_status', { required: 'Nationality is required' })}             
              // onChange={handleChange}
              />
              Non-Resident
            </div>
          </div>
          {resError && (
            <p ref={errorRes} className="text-danger fs-6">{resError}</p>
          )}
          <div className="row col-md-12 p-0 mx-auto mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name='nationality'
                label="Nationality"
                error
                {...register('nationality')}
                value={inputFields.nationality}
                onChange={handleChange}
              >


                <MenuItem value=''>select country</MenuItem>
                {countries.map((country) => (
                  <MenuItem
                    key={country.Row_ID} value={country.Cntry_ID}> {country.Cntry_Desc} </MenuItem>

                ))}
              </Select>
              <p className="text-danger fs-6"> {errors.nationality && <p>{errors.nationality.message}</p>} </p>
            </FormControl>
          </div>
          <div class="row">

            <div class="col-md-12 mb-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='marital_status'
                  label="Marital Status"
                  error
                  {...register('marital_status')}
                  onChange={handleChange}
                  value={inputFields.marital_status}
                // onChange={handleChange}
                >
                  <MenuItem value=''>select </MenuItem>
                  <MenuItem value='0'>Single </MenuItem>
                  <MenuItem value='1'>Married</MenuItem>
                </Select>
              </FormControl>
              <p className="text-danger fs-6"> {errors.marital_status && <p>{errors.marital_status.message}</p>} </p>

            </div>

          </div>

          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/6
              </button>
            ) : (
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <button type="submit" className="btn btn next-color text-white rounded"
            >
              Next <img height={20} src={gonext} /></button>
          </div>


        </div>
      </div>
    </form>


  )

}

export default StepTwo;

