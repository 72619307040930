import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardData from './CardData';
import Heading from './Heading';
import { grey } from '@mui/material/colors';
import CardsData from './CardsData';
import '../style.css';
import { AppContext } from '../../../context/AppContext';
import { useContext } from 'react';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1.2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '90px',
  marginTop: '40px',
  hover: {
    "&:hover": {
      backgroundColor: 'rgb(7, 177, 77, 0.42)'
    }
  }
}));
<Box
      sx={{
        width: '100%',
        maxWidth: 500,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 2,
      }}
    ></Box>
export default function FullWidthGrid(props) {
  const { step, setPage } = useContext(AppContext);
  const jsData = CardsData.map((data) => {
    return (
    
      <div className={`card col-lg-2  cardSize abc cards ${step === parseInt(data.id) && 'active'}`} key={data.id} onClick={() => setPage(parseInt(data.id))}>
    <img className='mt-2 imageSize' src={data.id == step ? data.imgsrc2 : data.imgsrc } alt="logo" />
          <br></br>
          <p className='mt-2 fontSize mob-font text-center'> <strong> {data.title} </strong></p>
          <p className='mt-2  mob-font-small'> <strong> {data.title2} </strong></p>
        </div>
      // <Grid item  className='cardSize'>
      //   <Item className={`abc cards cardStyle ${step === parseInt(data.id) && 'active'}`} key={data.id} onClick={() => setPage(parseInt(data.id))} >
      //     <img className='mt-2 imageSize' src={data.id == step ? data.imgsrc2 : data.imgsrc } alt="logo" />
      //     <br></br>
      //     <p className='mt-2 mob-font'> <strong> {data.title} </strong></p>
      //     <p className='mt-2 mob-font-small'> <strong> {data.title2} </strong></p>
      //   </Item>
      // </Grid>
    )
  }
  )
  return (
    <Grid container sx={{ marginTop: 2 }} spacing={4}>
      {jsData}
    </Grid>
  );
}