import React from "react";
import AccountText from "./AccountText";
import "./styles.css";
import bcim from '../../assets/back.png';


function AccountSection() {
  return (
    <div className="h-100 d-inline-blocks bg-color:primary  bg-image Ac-section"
      style={{
        height: '10%',
        backgroundImage: `url(${bcim})`,
      }}
    >
      <AccountText />
    
    </div>
  )
}

export default AccountSection;