import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';

// import Logo from "./components/introduction/Logo"; 
// import AccountSection from "./AccountSection";
// import AccountTabs from "./AccountText";
// import AccountText from "./AccountText";
import { Link } from 'react-router-dom';
import MultiStepProgressBar from "./MultiStepProgressbar/MultiStepProgressBar";
import Cards from "./Cards/Cards";
import CardsData from "./Cards/CardsData";
import Logo from "../introduction/Logo";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// import BackImg from "../../../assets/screenbg.png";
import BackImg from "../../assets/screenbg.png";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import StepFive from "./Steps/StepFive";
import StepSix from './Steps/StepSix';
import StepSeven from './Steps/StepSeven';
import Navigate from "./Steps/navigate";
import { AppContext } from '../../context/AppContext';
import './style.css';
import { useContext } from "react";

// document.addEventListener('contextmenu', (e) => e.preventDefault());


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1.2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '30px',
  marginTop: '40px',
  hover: {
    "&:hover": {
      backgroundColor: 'rgb(7, 177, 77, 0.42)'
    }
  }
}));
function BasicPage(props) {



    const { step, setPage } = useContext(AppContext);

    const handleinc = () => {
        // event.preventDefault();
        if (step < 6) {
            const nextStep = step + 1;
            setPage(nextStep)
        }
    };
    const handledec = () => {
        // event.preventDefault();
        if (step > 1) {
            const nextStep = step - 1;
            setPage(nextStep)
        }
    };

    return (
        <div className="container-fluid px-0 hedi" style={{
            backgroundImage: `url(${BackImg})`,
            resizeMode: 'repeat',
            height: '20%',
            width: '100%',
            overflowY: '20',
            // backgroundImage: `url(${BackImg})`,
        }}>
            <div className='container-fluid'>

                <Logo />
                <MultiStepProgressBar />
                
                <div style={{ marginLeft: 30 }} className='row cards'>
                    <Cards />
                    </div>
                <div id="targetDiv"></div>
                <div className="mobSize">
                    {step === 1 ? <StepOne /> : step === 2 ? <StepTwo /> : step === 3 ? <StepThree /> : step === 4 ? <StepFour /> : step === 5 ? <StepFive /> : step === 6 ? <StepSix /> : <StepSeven />}
                </div>
                <div className="row col-md-12">
                    <div className="col-md-3">
                    </div>
                </div>
              {step === 1 ? <Navigate /> : ''}


               
            </div>
        </div>
    );
}

export default BasicPage;