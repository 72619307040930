import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

function Classic() {
    const [open, setOpen] = React.useState(false);
   
  
  
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

    return(
        <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <ul style={{textAlign: 'left'}}>
              <li> Lowest commissions with self trade</li>
              <li> Leverage upto 5 times</li>
              <li> Access to robust Desktop, Web and Mobile trade platforms</li>
              <li> Call center support for executions</li>
              <li> Research reports</li>
              <li> Next day order placements</li>

            </ul>
          }
        >
          <Button onClick={handleTooltipOpen}><i style={{color: 'white'}} class="fa fa-info-circle" aria-hidden="true"></i></Button>
        </Tooltip>
    </ClickAwayListener> 
    )

    
 }

 export default Classic;