import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";
import axios from 'axios';
import "../style.css";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import { useRef } from "react";



function StepFour() {



  const handleRadioChange = (event) => {
    const value = event.target.value;
    // console.log(value);
    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
  };
  const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.
  const radioHandler = (status) => {
    setStatus(status);
    setInputFields({ ...inputFields, nom_relation: status });
  };

  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [previewFrontImage, setPreviewFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const [previewBackImage, setPreviewBackImage] = useState(null);
  const [errorss, setErrorr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [backImg, setBackImg] = useState(null);
  const [frontImg, setFrontImg] = useState(null);


  const [cnicFrontImage, setCnicFrontImage] = useState(null);
  const [cnicBackImage, setCnicBackImage] = useState(null);
  const [nomRelation, setNomRelation] = useState(null);


  const [inputFields, setInputFields] = useState({
    nominee_name: '',
    nominee_relation: '',
    nom_relation: '',
    front_cnic: "",
    back_cnic: "",


  });

  const { userData, setUserData, api, setApi, uin, setUin } = useContext(AppContext);

  useEffect(() => {
    // Access and use the user data here
    const { NomApplicantName, NomineeRelationship, nom_relation, NomcnicFImage, NomcnicBImage } = userData;
    setInputFields(
      {
        ...inputFields,
        nominee_name: NomApplicantName,
        nominee_relation: NomineeRelationship,
        nom_relation: nom_relation,
        front_cnic: NomcnicFImage,
        back_cnic: NomcnicBImage,

      })
    if (inputFields.front_cnic != null || inputFields.front_cnic != undefined) {
      setFrontImg(inputFields.front_cnic);
    }
    if (inputFields.back_cnic != null || inputFields.back_cnic != undefined) {
      setBackImg(inputFields.back_cnic);
    }
  }, [userData]);

  const schema = yup.object().shape({


    nominee_name: inputFields.nom_relation == 1 ?
      yup.string().max(100, 'Nominee name is too long')
        .required('Nominee name is required')
        .nullable()
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      : yup.string(),
    nominee_relation: inputFields.nom_relation == 1 ?
      yup.string()
        .required('Nominee relation is required')
        .nullable()
      : yup.string(),
  });


  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      province: inputFields.province,
    },
  });
  useEffect(() => {
    // Reset the form when the 'inputFields' change to update the initial values
    reset({
      province: inputFields.province,
      // Update initial values for other fields
    });
  }, [inputFields, reset]);

  const handleFrontChange = (event) => {


    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setCnicFrontImage("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFrontImage(file);
        setInputFields({ ...inputFields, front_cnic: file });
        setPreviewFrontImage(reader.result);
        // setErrorr(iban);
        setFrontImg(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFrontImageDelete = () => {
    setSelectedFrontImage(null);
    setPreviewFrontImage(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      front_cnic: null,
    }));
    setFrontImg(null);
    setErrorr(null);
  };
  const handleBackChange = (event) => {

    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setCnicBackImage("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedBackImage(file);
        setInputFields({ ...inputFields, back_cnic: file });
        setPreviewBackImage(reader.result);
        setBackImg(null);
        // setErrorr(iban);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleBackImageDelete = () => {
    setSelectedBackImage(null);
    setPreviewBackImage(null);
    setErrorr(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      back_cnic: null,
    }));
    setBackImg(null);

  };

  const errorRefFront = useRef(null);
  const errorRefBack = useRef(null);
  const errorNom = useRef(null);


  const onSubmit = (event) => {

    if (api == 4 || api >= 4) {

      if (!inputFields.nom_relation) {
        setNomRelation('Nominee field is required ')
        // Perform necessary error handling or display an error message to the user
        errorNom.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }

      if (!inputFields.front_cnic && inputFields.nom_relation == '1') {
        setCnicFrontImage('please upload front Cnic ')
        // Perform necessary error handling or display an error message to the user
        errorRefFront.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      if (!inputFields.back_cnic && inputFields.nom_relation == '1') {
        setCnicBackImage('please upload back Cnic')
        // Perform necessary error handling or display an error message to the user
        errorRefBack.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      setIsLoading(true);
      axios.post(`https://aofapi.bmatrade.com/api/step-four/${uin}`, inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          // Add any other headers you need
        },
      })
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          setApi(5);

          // Handle the response data
          if (step < 6) {
            const nextStep = step + 1;
            console.log(nextStep);
            setPage(nextStep)
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          // Handle any errors that occurred during the request
        });
    }
    else {
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };



  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://aofapi.test',
  };



  // const handleSubmit = (event) => {

  // };

  const { step, setPage } = useContext(AppContext);

  const handleinc = () => {
    // event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [imageErrorMessage, setImageErrorMessage] = useState('');

  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const fcnic = inputFields.front_cnic;
  const backcnic = inputFields.back_cnic;


  const frontUrl = `${baseUrl}${fcnic}`;
  const backUrl = `${baseUrl}${backcnic}`;


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        <div className="col-md-6">
          <strong className="font-color-theme"> Nominee  </strong>
          <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">
            <div className="col-md-8 mt-1">
              <p className="font-color-grey">Do you want to add Nominee?</p>
            </div>
            <div className="col-md-4 py-0 mr-5">
              <Radio
                checked={inputFields.nom_relation == 2} onClick={(e) => radioHandler(2)}
                // onChange={handleRadioChange}
                value={inputFields.nom_relation}
                name="nom_relation"
                inputProps={{ 'aria-label': '01' }}
                sx={{ color: '#c42026!important', }}
              />
              No
              <Radio
                checked={inputFields.nom_relation == 1} onClick={(e) => radioHandler(1)}
                value={inputFields.nom_relation}
                name="nom_relation"
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': '02' }}
              />
              Yes
            </div>
          </div>
          {nomRelation && (
            <p ref={errorNom} className="text-danger fs-6">{nomRelation}</p>
          )}

          {inputFields.nom_relation == 1 && (

            <>
              <label className="font-color-grey text-height mb-4">
                Would you like to add a nominee? In the event of death of the Account Holder(s), the Nominee shall be
                entitled to receive securities / cash available in the aaccount of the Account Holder(s) after set-off against
                losses and liabilities in the Account.

              </label>


              <div class="row mt-5">
                <div class="col-md-6 mb-4">
                  <TextField
                    error
                    id="outlined-error"
                    label="Name"
                    fullWidth
                    name="nominee_name"
                    {...register('nominee_name')}
                    value={inputFields.nominee_name}
                    onChange={handleChange}
                  />
                  <p className="text-danger fs-6"> {errors.nominee_name && <p>{errors.nominee_name.message}</p>} </p>
                </div>
                <div class="col-md-6 mb-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Relationship</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="nominee_relation"
                      {...register('nominee_relation')}
                      onChange={handleChange}
                      value={inputFields.nominee_relation}
                      label="Relationship"
                      error
                    >
                      <MenuItem>Please select relationship </MenuItem>
                      <MenuItem value='1'>Spouse </MenuItem>
                      <MenuItem value='2'>Father</MenuItem>
                      <MenuItem value='3'>Mother</MenuItem>
                      <MenuItem value='4'>Brother</MenuItem>
                      <MenuItem value='5'>Daughter </MenuItem>
                      <MenuItem value='6'>Son </MenuItem>

                    </Select>
                  </FormControl>
                  <p className="text-danger fs-6"> {errors.nominee_relation && <p>{errors.nominee_relation.message}</p>} </p>

                </div>
              </div>
              <div className="row">

                <div className="row col-md-6 p-0 mx-auto mt-2 mb-4">
                  {!inputFields.front_cnic ? (
                    <>
                      <label htmlFor="imageInput">
                        <Button
                          variant="text"
                          component="label"
                          className="nic_uploadBtn"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <div>
                            <AddAPhotoIcon />
                          </div>
                          Nom Front CNIC
                          <input
                            hidden
                            className="form-control"
                            id="front_cnic"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFrontChange}
                            type="file"
                            name="front_cnic"

                          />
                        </Button>

                        {cnicFrontImage && (
                          <p ref={errorRefFront} className="text-danger fs-6">{cnicFrontImage}</p>
                        )}
                      </label>
                    </>
                  ) : (
                    <>
                      <div className="preview">
                        <div className="nicPreview">

                          {/* <img src={previewImage} alt="Preview" /> */}
                          {frontImg != null || frontImg != undefined ? (

                            <img src={frontUrl} alt="Preview" />



                          ) : (
                            <img src={previewFrontImage} alt="Preview" />

                          )
                          }

                          <button
                            className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                            onClick={handleFrontImageDelete}
                          >
                            <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                          </button>


                        </div>
                      </div>
                    </>
                  )}



                </div>
                <div className="row col-md-6 p-0 mx-auto mt-2 mb-4">
                  {!inputFields.back_cnic ? (
                    <>
                      <label htmlFor="imageInput">
                        <Button
                          variant="text"
                          component="label"
                          className="nic_uploadBtn"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <div>
                            <AddAPhotoIcon />
                          </div>
                          Nom Back CNIC
                          <input
                            hidden
                            className="form-control"
                            id="back_cnic"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleBackChange}
                            type="file"
                            name="back_cnic"

                          />
                        </Button>

                        {cnicBackImage && (
                          <p ref={errorRefBack} className="text-danger fs-6">{cnicBackImage}</p>
                        )}
                      </label>
                    </>
                  ) : (
                    <>
                      <div className="preview">
                        <div className="nicPreview">

                          {/* <img src={previewImage} alt="Preview" /> */}
                          {backImg != null || backImg != undefined ? (

                            <img src={backUrl} alt="Preview" />
                          ) : (
                            <img src={previewBackImage} alt="Preview" />
                          )
                          }

                          <button
                            className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                            onClick={handleBackImageDelete}
                          >
                            <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                          </button>


                        </div>
                      </div>
                    </>
                  )}



                </div>

              </div>
            </>
          )}

          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/6
              </button>
            ) : (
              <div className="spinner-border  text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <button type="submit" className="btn btn next-color text-white rounded"
            >
              Next <img height={20} src={gonext} /></button>
          </div>
        </div>
      </div>
    </form>
  )

}

export default StepFour;

