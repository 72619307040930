import React from "react";
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import "../style.css";
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from "react-hot-toast";
import { useRef } from "react";

function StepSix() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [showDiv, setShowDiv] = useState(false);
  console.log(showDiv);
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
    setShowDiv(value === '2'); // Set showDiv to true if option1 is selected, otherwise false
  };
  const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.
  const radioHandler = (status) => {
    setStatus(status);
    setInputFields({ ...inputFields, zakat_status: status });
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorss, setErrorr] = useState(null);
  const [zakatDecleration, setZakatDecleration] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [imageCheck, setImageCheck] = useState(null);

  const [zakatStatus, setZakatStatus] = useState(null);

  const [inputFields, setInputFields] = useState({
    zakat_status: '',
    zakat_decleration: '',
  });
  const { userData, setUserData, api, setApi, uin, setUin } = useContext(AppContext);
  useEffect(() => {
    // Access and use the user data here
    const { ZakatStatus, ZakatImage } = userData;
    setInputFields(
      {
        ...inputFields,
        zakat_status: ZakatStatus,
        zakat_decleration: ZakatImage,
      })
    if (inputFields.zakat_decleration != null || inputFields.zakat_decleration != undefined) {
      setImageCheck(inputFields.zakat_decleration)
    }


  }, [userData]);
  const schema = yup.object().shape({

  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      province: inputFields.province,
    },
  });
  useEffect(() => {
    reset({
      province: inputFields.province,
    });
  }, [inputFields, reset]);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setImageError("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file);
        setInputFields({ ...inputFields, zakat_decleration: file });
        setPreviewImage(reader.result);
        setErrorr(zakatDecleration);
        setZakatDecleration(null);
        setImageCheck(null);

      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      zakat_decleration: null,
    }));
    setImageCheck(null);
  };
  const opendoc = () => {
    const link = document.createElement("a");
    link.download = `download`;
    link.href = process.env.PUBLIC_URL + '/solmen.pdf';
    link.click();
  };

  const errorRef = useRef(null);
  const errrorZakat = useRef(null);

  const onSubmit = (event) => {

    if (!inputFields.zakat_status) {
      setZakatStatus('Zakat Status is required field ')
      // Perform necessary error handling or display an error message to the user
      errrorZakat.current.scrollIntoView({ behavior: 'smooth' });

      return;
    }

    if (api == 6 || api >= 6) {

      if (!inputFields.zakat_decleration && (inputFields.zakat_status == '6' || inputFields.zakat_status == '7')) {
        setImageError('Please upload Zakat Decleration ')
        errorRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }


      setIsLoading(true);

      axios.post(`https://aofapi.bmatrade.com/api/step-six/${uin}`, inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          // Add any other headers you need
        },
      })
        .then((response) => {
          console.log(response);
          setIsLoading(false);

          console.log(response.data.success);

          if (response.data.success === 'true') {
            // Handle the response data

            if (step < 7) {
              const nextStep = step + 1;
              console.log(nextStep);
              setPage(nextStep)
            }
            setApi(7);
          }
          else {
            toast.error(`some fields are required`);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          // Handle any errors that occurred during the request
        });
    }
    else {
      // event.preventDefault();
      toast.error(`please first proceed with step ${api}`);

    }
  };

  const onDownloadNon = () => {
    const link = document.createElement("a");
    link.download = `download`;
    link.href = process.env.PUBLIC_URL + '/solmen.pdf';
    link.click();
  };
  const onDownloadMus = () => {
    const link = document.createElement("a");
    link.download = `download`;
    link.href = process.env.PUBLIC_URL + '/cz50.doc';
    link.click();
  };

  const { step, setPage } = useContext(AppContext);

  const handleinc = () => {
    // event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const imgii = inputFields.zakat_decleration;
  const imageUrl = `${baseUrl}${imgii}`;



  return (
    <form id="contactform" onSubmit={handleSubmit(onSubmit)}>
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center">
        <div className="col-md-6">
          <strong className="font-color-theme"> Zakat Status  </strong>
          <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">

            <div className="col-md-12 py-0 mr-5">
              <Radio
                checked={inputFields.zakat_status == 5} onClick={(e) => radioHandler(5)}
                value={inputFields.zakat_status}
                name="zakat_status"
                inputProps={{ 'aria-label': 'A' }}
                sx={{ color: '#c42026!important', }}

              />
              Zakat Deductible

              <Radio
                checked={inputFields.zakat_status == 6} onClick={(e) => radioHandler(6)}
                value={inputFields.zakat_status}
                name="zakat_status"
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': 'B' }}
              />
              Zakat Non-Deductible

              <Radio
                checked={inputFields.zakat_status == 7} onClick={(e) => radioHandler(7)}
                value={inputFields.zakat_status}
                name="zakat_status"
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': 'B' }}
              />
              Not Applicable


            </div>

          </div>
          {zakatStatus && (
            <p ref={errrorZakat} className="text-danger fs-6">{zakatStatus}</p>
          )}

          {(inputFields.zakat_status == '6' || inputFields.zakat_status == '7') && (
            <>
              <strong className="font-color-theme "> Download form as per your relegion  </strong>

              <div>
                <div className="row col-md-12 mx-auto mt-2 mb-4  rounded py-1 border border-danger">

                  <div className="col-md-6 col-sm-6 py-3  justify-content-center text-center">

                    For Non-Muslims :  &nbsp;

                    <i style={{ color: '#c42026' }} onClick={onDownloadNon} className="fa fa-download" aria-hidden="true">
                    </i>


                    {/* <button className="btn btn mx-3 next-color"> 
 </button> */}



                  </div>
                  <div className="col-md-6 py-3 col-sm-6  justify-content-center text-center">

                    For Muslims :  &nbsp;
                    <i style={{ color: '#c42026' }} onClick={onDownloadMus} className="fa fa-download" aria-hidden="true"></i>
                  </div>
                </div>

              </div>


              <div>
                <label style={{ color: '#c42026' }}><strong>Upload zakat decleration form </strong></label>
              </div>
              <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
                {!inputFields.zakat_decleration ? (
                  <>
                    <label htmlFor="imageInput">
                      <Button
                        variant="text"
                        component="label"
                        className="nic_uploadBtn"
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div>
                          <AddAPhotoIcon />
                        </div>
                        Zakat Decleration
                        <input
                          hidden
                          className="form-control"
                          id="image"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleImageChange}
                          type="file"
                          name="image"

                        />
                      </Button>
                      {imageError && (
                        <p ref={errorRef} className="text-danger fs-6">{imageError}</p>
                      )}
                    </label>
                  </>
                ) : (
                  <>
                    <div className="preview">
                      <div className="nicPreview">

                        {/* <img src={previewImage} alt="Preview" /> */}
                        {imageCheck != null || imageCheck != undefined ? (
                          <img src={imageUrl} alt="Preview" />

                        ) : (
                          <img src={previewImage} alt="Preview" />

                        )
                        }

                        <button
                          className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                          onClick={handleImageDelete}
                        >
                          <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                        </button>


                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div>

          
          </div>
          {/* <Checkbox defaultChecked size="small" /> I agree, Terms & Conditions */}

          <div className=" d-flex justify-content-between my-5">
            <button className="btn btn pre-color"
              onClick={handledec}>
              <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/6
              </button>
            ) : (
              <div className="spinner-border  text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <button type="submit" className="btn btn next-color text-white rounded"
            >
              Next <img height={20} src={gonext} /></button>
          </div>
        </div>
      </div>
    </form>
  )
}
export default StepSix;

