import React from "react";
import BackImg from "../../../assets/wh.png";
import Logo from "../../introduction/Logo";
import Premium from "./Premium";
import Classic from "./Classic";
import Sahulat from "./Sahulat";
import { Link } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";

function Docs() {
    
    const { account, setAccount } = useContext(AppContext);


    return (
        <div class="vh-100 d-flex justify-content-center align-items-center thankyou-bg">
            <div style={{ backgroundImage: `url(${BackImg})` }} class="col-md-12 vh-100 bg-white shadow-md p-2">
                <div class="mb-4 text-center">
                    <Logo />
                </div>
                
                <div class="text-center">
               <h4> <strong style={{ color: '#c42026' }}>Documents required for {account === 'PRE' ? 'Premium' : account === 'SAH' ? 'Sahulat' : 'Classic'} Account</strong></h4>
            </div>
                {account === 'PRE' ? <Premium /> : account === 'SAH' ? <Sahulat /> : <Classic />  }
                <div className="row col-md-12">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4 d-flex justify-content-between pb-5">
                        <Link to="/">
                            <button className="btn btn pre-color">
                                <strong style={{ color: '#c42026' }}> Previous</strong></button>
                        </Link>
                        <Link to="/account-opening">
                            <button className="btn btn pre-color">
                                <strong style={{ color: '#c42026' }}> Next</strong></button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default Docs;