import React from "react";



function Premium() {

    return (



        <div className="row col-md-12">
            <div className="col-md-4">
            </div>
            <div className="col-md-8  justify-content-left py-5">

                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}></i>
                    &nbsp;  CNIC
                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}> </i>
                    &nbsp; Mobile Number

                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}></i>
                    &nbsp;   IBAN

                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}></i>
                    &nbsp; Source of income

                </p>
                <p>
                    <i class="fa fa-check-circle" style={{ fontSize: '15px', color: '#c42026' }}> </i>
                    &nbsp;  Proof of address

                </p>

            </div>
        </div>


    )

}


export default Premium;