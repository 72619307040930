import React from "react";
import { useEffect } from "react";

import BackImg from "../../../assets/wh.png";
import Logo from "../../introduction/Logo";
import checkmark from "../../../assets/thankyou.gif";
import "../style.css";



const Thanks = () =>


{


   return( 
    
    <div  class="vh-100 d-flex justify-content-center align-items-center thankyou-bg">
    <div style={{  backgroundImage: `url(${BackImg})`}} class="col-md-12 vh-100 bg-white shadow-md p-2">
        <div class="mb-4 text-center">
            <Logo />
            <img className="mt-3" src={checkmark} height={140} alt="Animated GIF" />
        </div>
        <div class="text-center">
            <h1 className="thank-color">Thank You!</h1>
            <p><strong>Your request has been succesfully submitted <br />
              </strong> </p>
        </div>
        <hr style={{color: 'red',height: '2px'}} />
        <div class="mt-5 text-center">
        <p><strong className="thank-color"> Note :</strong> Please share your OTP with the Customer Service team via email or on WhatsApp. Once received :) </p>
            <p>Please be assured that our Customer Services team will reach out to you to complete the process within the next working day </p>
            <p>If have any queries, please do not hesitate to contact our CS team on <strong>+92 21 111 262 111 </strong> or send us a WhatsApp message on <strong>+92 309 5621482 </strong> </p>
        </div>
    </div>
    </div>
   )
} 
export default Thanks;