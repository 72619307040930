import React from "react";
import Logo from "./Logo";
import AccountSection from "./AccountSection";
import { useEffect } from "react";

import AccountTabs from "./AccountText";
import AccountText from "./AccountText";


function Main() {

  useEffect(() => {
    window.onbeforeunload = () => {
      window.location.href = '/';
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);


  
  return (
    <div className="container-fluid px-0">
      <Logo />
      <AccountSection />
    </div>
  )

}


export default Main;