import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

const GoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize('your-tracking-id');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Helmet>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-1C82XD0SQ9"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-1C82XD0SQ9');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;