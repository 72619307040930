
import React, { Component } from 'react';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: true, // Initially, show the button
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    // Determine when to hide the button (adjust as needed)
    if (scrollPosition > 200) {
      this.setState({ showButton: false });
    } else {
      this.setState({ showButton: true });
    }
  };

  scrollToSection = () => {
    const element = document.getElementById("targetDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { showButton } = this.state;
    
    return (
      <div>
        
        {showButton && (
          <button onClick={this.scrollToSection} className="sticky-button">
            <i class="fa fa-arrow-down" aria-hidden="true"></i>

          </button>
        )}
      </div>
    );
  }
}

export default Navigation;