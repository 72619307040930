import React from "react";
import "../style.css";
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";




function TermsAndCondition(props) {


    
const { account , tradercheck  } = useContext(AppContext);


 


    return (
        <>

            <div>
                <h6><strong> Summary of Charges </strong></h6>
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="headFont">Charge

                            </td>
                            <td className="headFont">Frequency

                            </td>
                            <td className="headFont">Amount

                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont">Custody Fees</td>
                            <td className="dataFont">Monthly</td>
                            <td className="dataFont">0.03% per annum on market value of shares</td>
                        </tr>
                        <tr>
                            <td className="dataFont">CDC Account Maintenance Fee</td>
                            <td className="dataFont">Annual</td>
                            <td className="dataFont">PKR 400</td>
                        </tr>
                        <tr>
                            <td className="dataFont">NCCPL UIN Maintenance Fee</td>
                            <td className="dataFont">Annual</td>
                            <td className="dataFont">PKR 300</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {(account === 'CLS') && (
            <div>
                <table className="bordered-table mt-3">
                    <tr>
                        {/* <td style={{textAlign: "left"}} className=" borderBottom headFont">Classic / Sahulat Account</td> */}
                    </tr>
                    </table>    
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="headFont borderBottom">Commission Structure</td>
                            <td className="headFont">Shares Range (Cost of share in PKR)

                            </td>
                            <td className="headFont">Commission per share (Paisa)

                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont borderBottom borderTop"></td>
                            <td className="dataFont  ">0.01 - 20.00</td>
                            <td className="dataFont">0.03</td>
                        </tr>
                        <tr>
                            {/* <td className="dataFont borderRight borderTop borderBottom"></td> */}
                           
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderTop"></td>
                            <td className="dataFont ">20.01 & above</td>
                            <td className="dataFont">0.15% of value</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderTop">Sindh Sales Tax</td>
                            <td className="dataFont borderRight">13% of Brokerage Commission</td>
                            <td className="dataFont borderLeft "></td>
                        </tr>
                       
                    </tbody>
                </table>
                 <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Daily Rollover Charges for Leverage Trading</td>
                    </tr>
                    </table>               
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future / Provisional</td>
                            <td className="headFont w-75 borderRight borderBottom "> </td>
                            <td className="borderLeft borderBottom"> </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont   borderRight borderTop borderBottom">PKR 0.005 per share</td>
                            <td className="dataFont borderLeft borderTop borderBottom"></td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop borderRight"></td>
                            <td className="dataFont borderLeft borderTop"></td>
                        </tr>
                       
                       
                    </tbody>
                </table>
                <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Margin Requirements for Leverage Trading</td>
                    </tr>
                    </table> 
                    <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future </td>
                            <td className="dataFont w-75 ">25% </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont    borderTop ">25%</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop "> 15%</td>
                        </tr>
                       
                       
                    </tbody>
                </table>
              
            </div>
            )}
             {(account === 'SAH') && (
            <div>
                <table className="bordered-table mt-3">
                    <tr>
                        {/* <td style={{textAlign: "left"}} className=" borderBottom headFont">Classic / Sahulat Account</td> */}
                    </tr>
                    </table>  



                    

                       {tradercheck === 'trader' ? (
                         <table className="bordered-table">
                         <thead>
                             <tr>
                                 <td className="headFont borderBottom">Commission Structure</td>
                                 <td className="headFont">Shares Range (Cost of share in PKR)
     
                                 </td>
                                 <td className="headFont">Commission per share (Paisa)
     
                                 </td>
                             </tr>
                         </thead>
                         <tbody>
                             <tr>
                                 <td className="dataFont borderBottom borderTop"></td>
                                 <td className="dataFont  ">0.01 - 2.99</td>
                                 <td className="dataFont">0.03</td>
                                 
                             </tr>
                             <tr>
                                 {/* <td className="dataFont borderRight borderTop borderBottom"></td> */}
                                
                             </tr>
                             <tr>
                             <td className="dataFont borderRight borderBottom borderTop"></td>
                                 <td className="dataFont ">3.00 - 40.00</td>
                                 <td className="dataFont">0.06</td>
                             </tr>
                             <tr>
                             <td className="dataFont borderRight borderTop"></td>
                                 <td className="dataFont ">40.01 & above</td>
                                 <td className="dataFont">0.15% of value</td>
                             </tr>
                             <tr>
                             <td className="dataFont borderRight borderTop">Sindh Sales Tax</td>
                                 <td className="dataFont borderRight">13% of Brokerage Commission</td>
                                 <td className="dataFont borderLeft "></td>
                             </tr>
                             
                            
                         </tbody>
                     </table>
                                
                                ) : (
                                    <table className="bordered-table">
                                    <thead>
                                        <tr>
                                            <td className="headFont borderBottom">Commission Structure</td>
                                            <td className="headFont">Shares Range (Cost of share in PKR)
    
                                            </td>
                                            <td className="headFont">Commission per share (Paisa)
    
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="dataFont borderBottom borderTop"></td>
                                            <td className="dataFont  ">0.01 - 20.00</td>
                                            <td className="dataFont">0.03</td>
                                        </tr>
                                        <tr>
                                            {/* <td className="dataFont borderRight borderTop borderBottom"></td> */}
                                        
                                        </tr>
                                        <tr>
                                        <td className="dataFont borderRight borderTop"></td>
                                            <td className="dataFont ">20.01 & above</td>
                                            <td className="dataFont">0.15% of value</td>
                                        </tr>
                                        <tr>
                                        <td className="dataFont borderRight borderTop">Sindh Sales Tax</td>
                                            <td className="dataFont borderRight">13% of Brokerage Commission</td>
                                            <td className="dataFont borderLeft "></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                                )}
                 <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Daily Rollover Charges for Leverage Trading</td>
                    </tr>
                    </table>               
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future / Provisional</td>
                            <td className="headFont w-75 borderRight borderBottom "> </td>
                            <td className="borderLeft borderBottom"> </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont   borderRight borderTop borderBottom">PKR 0.005 per share</td>
                            <td className="dataFont borderLeft borderTop borderBottom"></td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop borderRight"></td>
                            <td className="dataFont borderLeft borderTop"></td>
                        </tr>
                       
                       
                    </tbody>
                </table>
                <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Margin Requirements for Leverage Trading</td>
                    </tr>
                    </table> 
                    <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future </td>
                            <td className="dataFont w-75 ">25% </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont    borderTop ">25%</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop "> 15%</td>
                        </tr>
                       
                       
                    </tbody>
                </table>
              
            </div>
            )}


            {(account === 'PRE')  && (
            <div>
                {/* <h6 className="mt-3"><strong> </strong></h6> */}
                <table className="bordered-table mt-3">
                    <tr>
                        {/* <td style={{textAlign: "left"}} className=" borderBottom headFont">Premium Account</td> */}
                    </tr>
                    </table>    
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="headFont borderBottom">Commission Structure</td>
                            <td className="headFont">Shares Range (Cost of share in PKR)

                            </td>
                            <td className="headFont">Commission per share (Paisa)

                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont borderBottom borderTop"></td>
                            <td className="dataFont  ">0.01 - 2.99</td>
                            <td className="dataFont">0.03</td>
                            
                        </tr>
                        <tr>
                            {/* <td className="dataFont borderRight borderTop borderBottom"></td> */}
                           
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderBottom borderTop"></td>
                            <td className="dataFont ">3.00 - 40.00</td>
                            <td className="dataFont">0.06</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderTop"></td>
                            <td className="dataFont ">40.01 & above</td>
                            <td className="dataFont">0.15% of value</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderTop">Sindh Sales Tax</td>
                            <td className="dataFont borderRight">13% of Brokerage Commission</td>
                            <td className="dataFont borderLeft "></td>
                        </tr>
                        
                       
                    </tbody>
                </table>
                 <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Daily Rollover Charges for Leverage Trading</td>
                    </tr>
                    </table>               
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future / Provisional</td>
                            <td className="headFont w-75 borderRight borderBottom "> </td>
                            <td className="borderLeft borderBottom"> </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont   borderRight borderTop borderBottom">PKR 0.005 per share</td>
                            <td className="dataFont borderLeft borderTop borderBottom"></td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop borderRight"></td>
                            <td className="dataFont borderLeft borderTop"></td>
                        </tr>
                       
                       
                    </tbody>
                </table>
                <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Margin Requirements for Leverage Trading</td>
                    </tr>
                    </table> 
                    <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Future </td>
                            <td className="dataFont w-75  ">25% </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">MFS</td>
                            <td className="dataFont    borderTop ">25%</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">MTS</td>
                            <td className="dataFont w-50  borderTop "> 15%</td>
                        </tr>
                       
                       
                    </tbody>
                </table>
              
            </div>
                      )}

            <div>
                {/* <h6 className="mt-3"><strong> </strong></h6> */}
                <table className="bordered-table mt-3">
                    <tr>
                        <td style={{textAlign: "left"}} className=" borderBottom headFont">Transfer of physical shares</td>
                    </tr>
                    </table>    
                <table className="bordered-table">
                    <tbody>
                    
                       
                        <tr>
                        <td className="dataFont borderRight">Shares</td>
                            <td className="dataFont w-50">10 paisa per share</td>
                        </tr>
                        <tr>
                            <td className="dataFont ">TFC / WAPDA Bonds</td>
                            <td className="dataFont">PKR 1 per unit</td>
                        </tr>                    
                        
                       
                    </tbody>
                </table>
                 <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Corporate Action</td>
                    </tr>
                    </table>               
                <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont w-25 borderBottom">Subscription of Right Shares</td>
                            <td className="dataFont w-75   ">0.004 paisa per share for self subscription</td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop borderBottom"></td>
                            <td className="dataFont borderTop borderBottom"> 0.01 paisa per share for subscription via BMA Capital <br></br> OR<br></br>
                            Minimum PKR 250, maximum PKR 100,000</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight borderTop "> </td>
                        </tr>
                        <tr>
                            <td className="dataFont w-25 borderBottom">Withdrawal of Physical Shares</td>
                            <td className="dataFont w-75   ">25 paisa per share</td>

                        
                        </tr>
                        <tr>
                            <td className="dataFont  borderTop borderBottom"></td>
                            <td className="dataFont borderTop ">Redemption fee for Open End Funds 0.08% of market value</td>
                        </tr>
                        <tr>
                        <td className="dataFont "> Withdrawal of TFC / WAPDA Bonds </td>
                            <td className="dataFont w-50  borderTop ">PKR 60 per unit</td>
                        </tr>
                       
                       
                    </tbody>
                </table>
                <table className="bordered-table">
                    <tr>
                        <td style={{textAlign: "left"}} className="borderTop borderBottom headFont">Expenses</td>
                    </tr>
                    </table> 
                    <table className="bordered-table">
                    <thead>
                        <tr>
                            <td className="dataFont ">Courier / Registered post </td>
                            <td className="dataFont w-50"> At actual </td>

                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="dataFont  borderTop">Shares transfer stamps</td>
                            <td className="dataFont    borderTop ">At actual</td>
                        </tr>
                        <tr>
                        <td className="dataFont borderRight ">Other expenses</td>
                            <td className="dataFont borderTop"> At actual</td>
                        </tr>
                       
                    </tbody>
                </table>
              
            </div>
 <br></br>

            <h6 className="text-danger"><strong> MARGIN DISCLOSURE STATEMENT</strong></h6>
            <h6><strong>This statement forms a part of the Account Opening Form for Online Trading. </strong></h6>
            <h6>BMA Capital Management Limited (BMA), also referred to herein as the Broker is furnishing this document to the Account Holder(s) to provide some facts about
                purchasing Securities on margin, and to alert the Account Holder(s) as to the risks involved with trading Securities in a margin account. Before trading Securities in
                a margin account, the Account Holder(s) should carefully review the margin clause in the Special terms and Conditions for Online trading enunciated in the
                Account Opening Form. The Account Holder(s) should obtain clarification(s) from BMA regarding any questions or concerns he/she/they may have regarding the
                margin account. It is important that the Account Holder(s)understand(s) fully the risks involved in trading Securities on margin. These risks include the following:</h6>
            <br></br>
            <h6><strong> The Account Holder(s) can lose more funds than invested in the Account:</strong></h6>
            <h6>A decline in the value of Securities that are purchased on margin may require the Account Holder(s) to provide additional funds to the Broker to avoid
                forced sale of those Securities or other Securities or asset(s) in the Account Holder(s) account.</h6>
            <br></br>
            <h6><strong>The broker can force the sale of Securities or other asset(s) in the Account Holder(s) account</strong></h6>
            <h6>If the equity in the Account Holder(s) account falls below the maintenance margin requirements, the Broker, after the margin call notice period, can sell
                the Securities or other assets in the account to cover the margin deficiency. The Account Holder(s) also will be responsible for any shortfall in the
                account after such sale.</h6>
            <br></br>
            <h6><strong>Account Holder(s) are not entitled to choose which Securities or other assets in the account are liquidated or sold first to meet margin
                requirements</strong></h6>
            <h6>Because the Securities are collateral for margin trading, the Broker has the right to decide which Security(ies) to sell in order to protect its interests
                and/or meet margin requirements. .</h6>
            <br></br>
            <h6><strong>The Broker can amend its “in-house” maintenance margin requirements at any time and is required to provide the Account Holder(s) 3 (three)
                days advance written notice:</strong></h6>
            <h6>These changes in Broker’s policy often take place immediately and may result in the issuance of maintenance margin call. The Account Holder(s)
                failure to satisfy the call may cause the Broker to liquidate or sell Securities in his/her/their Account. .</h6>
            <br></br>
            <h6><strong>The Account Holder(s) are not entitled to an extension of time on margin call:
            </strong></h6>
            <h6>While an extension of time to meet margin requirements may be available to Account Holder(s) under certain conditions, the Account Holder(s) does
                not/do not have a right to the extension</h6>

            <br></br>
            <h6 className="text-danger"><strong> RISK DISCLOSURE DOCUMENT</strong></h6>

            <br></br>
            <h6>This Risk Disclosure document is prescribed by the Pakistan Stock Exchange Limited (PSX) under Clause 13(1) of the Securities Broker (Licensing and Operations)
                Regulations, 2016.</h6>
            <br></br>
            <h6>This document contains important information relating to various types of risks associated with trading and investment in financial products (equity securities, fixed income
                instruments, derivatives contracts etc.) being traded at PSX. The customers should carefully read this document before opening trading account with a broker. In case a
                customer suffers negative consequences or losses as a result of trading/investment, he/she shall be solely responsible for the same and PSX or Securities and Exchange
                Commission of Pakistan (SECP) shall not be held responsible/liable, in any manner whatsoever, for such negative consequences or losses.</h6>

            <br></br>
            <h6>The customers must acknowledge and accept that there can be no guaranteed profit or guaranteed return on their invested capital and under no circumstances a broker can
                provide customers such guarantee or fixed return on their investment in view of the fact that the prices of securities and futures contract can fall as well as rise depending on
                the market conditions and performance of the companies. Customers must understand that past performance is not a guide to future performance of the securities,
                contracts or market as a whole. In case the customers have any doubt or are unclear as to the risks/information disclosed in this document, PSX strongly recommends that
                such customer should seek an independent legal or financial advice in advance.
            </h6>

            <br></br>
            <h6>PSX neither singly or jointly and expressly nor impliedly guarantee nor make any representation concerning the completeness, accuracy and adequacy of the information
                contained in this document as this document discloses the risks and other significant aspects of trading/investment at the minimum level. PSX does not provide or purport to
                provide any advice and shall not be liable to any person who enters into a business relationship with a broker based on any information contained in this document. Any
                informa tion ontained in this document must not be construed as business/investment advice in any manner whatsoever.</h6>

            <br></br>

            <h6 className="text-danger"><strong> THE CUSTOMERS MUST BE AWARE OF AND ACQUAINTED WITH THE FOLLOWING:</strong></h6>
            <h6><strong>1. BASIC RISKS INVOLVED IN TRADING IN SECURITIES MARKET:</strong></h6>
            <br></br>

            <h6><strong>1.1 VOLATILITY RISK:</strong></h6>

            <br></br>
            <h6>Volatility risk is the risk of changes in the value of financial product in any direction. High volatility generally means that the values of securities/contracts can undergo
                dramatic upswings and/or downswings during a short period. Such a high volatility can be expected relatively more in illiquid or less frequently traded securities/contracts
                than in liquid or more frequently traded one. Due to volatility, the order of a customer may not be executed or only partially executed due to rapid change in the market
                prices. Such volatility can also cause price uncertainty of the market orders as the price at which the order is executed can be substantially different from the last available
                market price or may change significantly thereafter, resulting in a real or notional loss.</h6>

            <h6><strong>1.2 LIQUIDITY RISK:</strong></h6>

            <br></br>
            <h6>Liquidity refers to the ability of market participants to buy and/or sell securities expeditiously at a competitive price and with minimal price difference. Generally, it is assumed
                that more the numbers of orders available in ci market, greater is the liquidity. Liquidity is important because with greater liquidity, it is easier for customers to buy and/or sell
                securities swiftly and with minimal price difference and, as a result, customers are more likely to pay or receive a competitive price for their executed trades. Generally, lower
                liquidity can be expected in thinly traded instruments than in liquid or more frequently traded ones. As a result, order of customer may only be partially executed, or may be
                executed with relatively greater price difference or may not be executed at all. Under certain market conditions, it may be difficult or impossible for the customers to liquidate
                a position in the market at a reasonable price, when there are no outstanding orders either on the buy side or on the sell side, or if trading is halted in a security/contract due
                to any reason..</h6>

            <h6><strong>1.3 SPECULATIVE TRADING RISK:
            </strong></h6>

            <br></br>
            <h6>Speculation involves trading of a security/contract with the expectation that it will become more valuable in a very near future. These transactions are attempted to make
                profit from fluctuations in the market value of securities, rather than fundamental value of a security and/or underlying attributes embodied in the securities such as
                dividends, bonus or any other factor(s) materially affecting the price.Speculative trading results in an uncertain degree of gain or loss. Almost all investment activities involve
                speculative risks to some extent, as a customer has no idea whether an investment will be a blazing success or an utter failure. Day trading strategy is a common example
                of speculative trading in which customers buy and sell the same security/derivative within the same day, such that all obligations are netted off and closed and no settlement
                obligations stand. The customer indulging in a day-trading strategy needs to be more vigilant and informed than the customers investing for a longer period, as market may
                not move during the day as the day-trader originally anticipated, resulting in a loss to them.</h6>

            <h6><strong>1.4 RISK OF WIDER SPREAD:
            </strong></h6>

            <br></br>
            <h6>The Bid-Ask spread is the difference between the offer price and bid price of a security/contract quoted by the Market Makers or trading parties. The size of spread is
                affected by a number of factors such as liquidity, volatility, free float (the total number of shares outstanding that are readily available for trading) etc. Generally, low liquidity,
                high volatility and low free float levels of a security may result in relatively wider Bid-Ask Spread. The higher Bid-Ask spread can result in greater cost to customers.</h6>

            <h6><strong>1.5 RISK PERTAINING TO THE PRICE FLUCTUATIONS DUE TO CORPORATE ANNOUNCEMENT:
            </strong></h6>

            <br></br>
            <h6>The corporate announcements by the issuers for the corporate actions or any other material information may affect the price of the securities. These announcements
                combined with relatively lower liquidity of the security may result in significant price volatility. The customers, while making any investment decision in such
                securities/contracts, are advised to take into account such announcements. Moreover, the customers should be cautious and vigilant in case fake rumors are circulating in
                the market. The Customers are advised to refrain from acting purely based on such rumors rather take well informed investment decision in light of all facts and
                circumstances associated with such securities and their issuers.
            </h6>

            <h6><strong>1.6 RISK REDUCING ORDERS:
            </strong></h6>

            <br></br>
            <h6>The customers can place orders for limiting the losses to certain amounts, such as Limit Orders, Stop Loss Orders, and Market Orders etc. Customers must ask their
                brokers for detailed understanding of these order types. Customers must acknowledge that placement of such orders for limiting losses to certain extent may not always be
                an effective tool due to rapid movements in the prices of securities and, as a result, such orders may not be executed.
            </h6>

            <h6><strong>1.7 SYSTEM RISK:
            </strong></h6>

            <br></br>
            <h6>High volume trading will frequently occur at the market opening and before market close. Such high volumes may also occur at any point in the day causing delay in order
                execution or confirmation. During periods of volatility, on account of market participants contin uously modifying their order quantity or prices or placing fresh orders, there
                may be delays in order execution and its confirmations.</h6>

            <h6><strong>1.8 SYSTEMIC RISK:
            </strong></h6>

            <br></br>
            <h6>Systemic risk arises in exceptional circumstances and is the risk that the inability of one or more market participants to perform as expected will cause other participants to
                be unable to meet their obligations when due, thereby affecting the entire capital market.</h6>

            <h6><strong>1.9 SYSTEM AND NETWORKING RISK:
            </strong></h6>

            <br></br>
            <h6>Trading on the PSX is done electronically, based on satellite/leased line based communications, combination of technologies and computer systems to place and route
                orders. All these facilities and systems are vulnerable to temporary disruption or failure, or any such other problem/glitch, which may lead to failure to establish access to the
                trading system/network. Such limitation may result in delay in processing or processing of buy or sell orders in part only or non-processing of orders at all. As with any
                financial transaction, the customer may experience losses if orders cannot be executed normally due to systems failures on the part of exchange or broker. The losses may
                be greater if the broker having customers' position does not have adequate back-up systems or procedures. Accordingly, the Customers are cautioned to note that although
                these problems may be temporary in nature, but when the customers have outstanding open positions or unexecuted orders, these limitations represent a risk because of
                obligaions to settle all executed transactions.</h6>

            <h6><strong>1.10 RISK OF ONLINE SERVICES:
            </strong></h6>

            <br></br>
            <h6>The customers who trade or intend to trade online should fully understand the potential risks associated with online trading. Online trading may not be completely secure
                and reliable and may cause delay in transmitting information, execution of instructions due to technological barriers. Moreover, the customer acknowledges and fully
                understands that he/she shall be solely responsible for any consequences arising from disclosure of the access codes and/or passwords to any third person or any
                unauthorized use of the access codes and/or passwords.</h6>








        </>
    )
}

export default TermsAndCondition;