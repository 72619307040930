import React from "react";
import IMG from "../../assets/bmatrade.svg";


function Logo() {
  return (
    <div className="vh-40  d-flex justify-content-center align-items-center">
      <div className=" text-center">
        {/* <img height={65} src={process.env.PUBLIC_URL+ './assets/bmatrade.svg'} alt="logo" /> */}
        <img height={65} src={IMG} alt="logo" style={{ marginTop: '75px' }} />
      </div>
    </div>
  )
}

export default Logo;