import React, { useEffect, useRef, useState } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useContext } from "react";
import { AppContext } from '../../context/AppContext';
import Sahulat from "./Tooltip/Sahulat";
import Classic from "./Tooltip/Classic";
import Premium from "./Tooltip/Premium";
import "./styles.css";

function AccountTabs(props) {


  const { account, setAccount } = useContext(AppContext);

  // const handleButtonClick = (event) => {

  //   console.log(event.target.value);
  //   const value = event.target.value;
  //   setAccount(value);

  // };   
  const [open, setOpen] = React.useState(false);
  // const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);





  const handleTooltipClose3 = () => {
    setOpen3(false);
  };

  const handleTooltipOpen3 = () => {
    setOpen3(true);
  };


  const tabsRef = useRef(null);
  const tabRef0 = useRef(null);
  const tabRef1 = useRef(null);
  const tabRef2 = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);


  const [properties, setProperties] = useState({ width: tabRef0.current?.clientWidth, left: tabRef0.current?.getBoundingClientRect().left });

  const handleClick = (id) => {
    const accval = (id == 0 ? 'CLS' : id == 1 ? 'PRE' : 'SAH');
    setAccount(accval);
    let b = { tabRef0, tabRef1, tabRef2 };
    setProperties({ width: b['tabRef' + id].current?.clientWidth, left: b['tabRef' + id].current?.getBoundingClientRect().left })
    setSelectedItem(id);

  }

  console.log(selectedItem);
  return (

    <section>
      <div style={{ marginRight: '-10px' }} className="mx-0 rt-container">
        <div className="col-rt-12">
          <div className="Scriptcontent">
            <div className="tile" id="tile-1">

           
              <ul className="nav nav-tabs nav-justified bord" ref={tabsRef}>
                {/* <div className="slider" style={{ width: properties.width, left: properties.left - properties.width / 3 }}></div>  */}
                                
                <li className="nav-item" >
                  <div  onClick={() => handleClick(0)}>
                    <button value="CLS" className={`nav-item text-white button-style mb-3 dd ${selectedItem === 0 ? 'selected' : ''}`}><i value="cls " style={{ fontSize: 50 }} className="fa la">&#xf19c; </i>&nbsp;&nbsp; &nbsp; &nbsp;    Classic Account
                      <Classic />
                    </button>
                  </div>
                </li>
                {/* <div className="mini slider" style={{ width: properties.width, left: properties.left - properties.width / 3 }}></div>  */}
                <li className="nav-item">
                  <div ref={tabRef1} onClick={() => handleClick(1)} >
                    <button value="PRE" className={`nav-item text-white button-style mb-3 dd ${selectedItem === 1 ? 'selected' : ''}`}><i style={{ fontSize: 50 }} className="fa la">&#xf19c; </i>&nbsp;&nbsp; Premium Account
                      <Premium />
                    </button>
                  </div>
                </li>
                <li className="nav-item">
                  <div ref={tabRef2} id="contact-tab" onClick={() => handleClick(2)}  >
                    <button value="SAH" className={`nav-item text-white button-style mb-3 dd ${selectedItem === 2 ? 'selected' : ''}`}><i style={{ fontSize: 50 }} className="fa la">&#xf19c; </i>&nbsp;&nbsp; &nbsp;   Sahulat Account
                     <Sahulat />
                    </button>
                  </div>
                </li>
                
              </ul>

              


            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default AccountTabs;  