import React from "react";
import AccountTabs from "./AccountTabs";
import { Link } from 'react-router-dom';
import "./styles.css";

function AccountText() {
  return (

    <div className="row">
      <div className="mb-4 text-center mt-5">
        <h2 className="text-light"> Choose account type</h2>
      </div>
      <div className="col-md-10 mx-auto">
        <div className="row mx-auto w-100">
        
          <AccountTabs />
        </div>
      </div>
      <div className="mt-3 text-center ">
        <Link to="/required-docs">
          <button type="button" className="btn btn-light py-2 px-4 mt-3" style={{ borderRadius: '10px', color: "#c4161c" }}>
            <strong>Next
              <img className="" width={20} height={15} src={process.env.PUBLIC_URL + './assets/gonext.svg'} alt="logo" />
            </strong>
          </button>
        </Link>
      </div>
      <div className="mb-5 text-center mt-5">
        {/* <p className="text-light"> Click  here to get details about Acount types</p> */}
      </div>
    </div>
  )
}

export default AccountText;