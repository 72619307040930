  
  const CardsData = [
    {
     id : "1",
     sname :"",
     imgsrc : process.env.PUBLIC_URL+ './assets/intro.svg',
     imgsrc2 : process.env.PUBLIC_URL+ './assets/intro_white.svg',
     title  : "Introduction",
     title2 : "Intro",
      
    },
    {
        id : "2",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/data_ver_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/data_ver_white.svg',
        title  : "Data Verification",
        title2  : "Verification",
         
       },
       {
        id : "3",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/address_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/add_white.svg',
        title  : "Address",
        title2  : "Address",

         
       },
       {
        id : "4",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/nominee_information_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/nom_info_white.svg',
        title  : "Nominee Information",
        title2  : "Nominee",
         
       },
       {
        id : "5",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/emoloyer_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/employer_white.svg',
        title  : "Income Information",
        title2  : "Income",

         
       },
       {
        id : "6",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/zakat_status_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/zakat_white.svg',
        title  : "Zakat Status",
        title2  : "Zakat",
        
       },
       {
        id : "7",
        sname :"",
        imgsrc : process.env.PUBLIC_URL+ './assets/specimen_signature_red.svg',
        imgsrc2 : process.env.PUBLIC_URL+ './assets/specimen_white.svg',
        title  : "Specimen Signature",
        title2 : " Signature",
         
       }
       
    
   ]
 
   export  default CardsData;